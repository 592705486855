@import 'styles/breakpoints';
@import 'styles/typography';

.splitContentWrapper {
  padding: 24px 0;

  @include breakpoint(md, min) {
    padding: 72px 0;
  }

  .splitRow {
    display: flex;
    justify-content: space-between;
    column-gap: min(12.5%, 144px);
    flex-direction: column;
  }

  .splitLeft {
    width: 100%;

    @include breakpoint(md, min) {
      width: 37.5%;
    }
  }

  .splitRight {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .splitRightContent {
    padding: 24px 0;
  }

  [class*='_richText_'] {
    min-height: 150px;

    h2 {
      margin-bottom: 8px;
    }

    p + p {
      margin-top: 14px;
    }
  }

  [class*='_ajustableItem_'] {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  [class*='_button_'] {
    margin-top: 24px;
  }

  [class*='_buttonCaption_'] {
    margin-top: 24px;

    [class*='_button_'] {
      margin-top: 0;
    }
  }

  &.rtl .splitRow {
    @include breakpoint(md, min) {
      flex-direction: row;
    }
  }

  &.ltr .splitRow {
    @include breakpoint(md, min) {
      flex-direction: row-reverse;
    }
  }

  &.questions {
    .splitRow {
      @include breakpoint(md, max) {
        flex-direction: column-reverse;
      }
    }

    .splitRightContent {
      @include breakpoint(md, max) {
        text-align: center;
        margin: auto;
        padding-bottom: 32px;
      }
    }

    [class*='_richText_'] {
      h2 {
        @include h1-desktop-h2-mobile;
      }

      @include breakpoint(md, max) {
        min-height: 120px;
      }
    }

    [class*='_ajustableImage_']::before {
      @include breakpoint(md, max) {
        top: auto;
        bottom: 0;
        height: 100px;
      }
    }

    [class*='_ajustableItem_'] {
      @include breakpoint(md, max) {
        padding-top: 0;
        padding-bottom: 8px;
      }
    }
  }
}
