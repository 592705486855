@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';

.ajustableItem {
  position: relative;
  height: 158px;
  padding: 8px 0 0;
  text-align: center;
  z-index: 10;

  @include breakpoint(sm, min) {
    height: 188px;
  }

  @include breakpoint(md, min) {
    height: 244px;
    padding: 24px 0;
  }

  img {
    display: inline-block;
    width: auto;
    height: 100%;
  }
}

.ajustableImage {
  width: 100%;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  justify-content: center;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 124px;
    position: absolute;
    left: 0;
    top: 0;
    background: $brand-primary-tints-100;
    z-index: 1;

    @include breakpoint(sm, min) {
      height: 148px;
    }

    @include breakpoint(md, min) {
      height: 100%;
    }
  }

  figcaption {
    @include caption;
    display: inline-block;
    margin-bottom: 24px;
    margin-top: auto;
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 10;

    @include breakpoint(md, min) {
      position: relative;
      left: 0;
      top: 0;
    }

    @include breakpoint(sm, max) {
      font-size: $font-size-xxxxs;
    }

    &.small {
      max-width: 105px;

      @include breakpoint(md, min) {
        font-size: $font-size-xxs;
        max-width: none;
      }
    }
  }

  figcaption + .ajustableItem {
    margin-top: auto;
  }

  &.primaryLight::before {
    background: $brand-primary-tints-100;
  }

  &.secondaryLight::before {
    background: $brand-secondary-tints-100;
  }

  &.secondary2Light::before {
    background: $brand-secondary-2-tints-100;
  }

  &.secondary3Light::before {
    background: $brand-secondary-3-tints-100;
  }

  &.secondary4Light::before {
    background: $brand-secondary-4-tints-100;
  }

  &.large {
    .ajustableItem {
      @include breakpoint(md, min) {
        height: 268px;
      }
    }
  }

  &.small {
    .ajustableItem {
      @include breakpoint(md, min) {
        height: 208px;
      }
    }
  }

  &.xl {
    .ajustableItem {
      @include breakpoint(md, min) {
        height: 243px;
        width: auto;
        max-height: 243px;
      }

      @include breakpoint(lg, min) {
        height: 343px;
        width: auto;
        max-height: 343px;
      }
    }
  }

  .ajustableBreakpoint {
    height: 100%;

    [class*='_largerItem_'],
    [class*='_smallerItem_'] {
      height: 100%;
    }
  }

  .effectiveMobile {
    width: 212px;
    height: auto;
  }
}
