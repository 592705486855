@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';

.faqAccordion {
  margin: 0;

  [class*='_item_']:last-child {
    border-bottom: 1px solid $brand-primary-tints-accordion;
  }
}

.faqWrapper {
  padding: 48px 0 0;
  margin-top: 0;

  @include breakpoint(lg, min) {
    padding: 72px 0;
  }

  h2 {
    @include h2;
    text-align: center;
  }
}

.colWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    width: 100%;
    max-width: 864px;
    margin: 24px 0 16px 0;
  }

  .faqAccordion {
    padding: 0 12px;
    width: 100%;

    max-width: 864px;
  }

  @include breakpoint(lg, min) {
    h2 {
      margin: 0 0 32px 0;
    }
  }
}

.faqContent {
  padding-bottom: 24px;

  [class*='_richText_'] a {
    color: $text-color-link;

    &:hover {
      color: $text-color-link-hover;
    }
  }
}
