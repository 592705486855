@import 'styles/breakpoints.scss';
@import 'styles/typography.scss';
@import 'Nori/Button/mixin.scss';

.faqHeader {
  display: block;
}

.faqItemTitle {
  display: block;
  flex: 1;
  margin-right: 40px;
  @include h3;
}

.accordionButton {
  @include resetButtonStyles;
  position: relative;
  padding: 24px 0;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  background: $transparent;
  width: 100%;
  border-top: 1px solid $brand-primary-tints-accordion;

  svg {
    width: 25px;
    color: $brand-secondary-5;
    transition: transform $timing-elastic;
    transform: rotate(180deg);
  }

  svg.closed {
    transform: rotate(0);
  }
}
