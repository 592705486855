@import 'styles/breakpoints';
@import 'styles/typography';

.moreInformation {
  display: none;

  @include breakpoint(lg, min) {
    display: block;
  }
}

.splitAccordion {
  background: $brand-secondary-2-tints-100;
  padding: 32px 0px;

  .splitBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    .content {
      width: 100%;

      h2 + p {
        margin-top: 8px;
      }
    }

    .accordion {
      width: 100%;
      background: $brand-white;
      padding: 0px 24px;
      border-radius: 24px;

      .faqContent {
        margin-bottom: 24px;
      }
    }

    .ctaWrapper {
      margin-top: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;
    }

    @include breakpoint(lg, min) {
      flex-direction: row;
      gap: 36px;
    }
  }

  .handImage {
    display: none;
    position: absolute;
    left: 10%;
    bottom: -96px;
  }

  .caption {
    display: none;
    @include caption;
    position: absolute;
    bottom: -72px;
  }

  @include breakpoint(lg, min) {
    padding: 96px 0px;

    .handImage,
    .caption {
      display: block;
    }
  }
}

.spanishSplitAccordion.splitAccordion {
  .handImage {
    width: 100%;
    height: auto;
  }

  .handImageWrapper {
    position: absolute;
    width: 258px;
    height: auto;
    bottom: 0;
    left: 13%;
  }

  .caption {
    max-width: 210px;
  }
}

.videoWrapper {
  padding: 24px 0 48px;
}

.videoTitle {
  @include h2;
  text-align: center;
  margin-top: 10px;
}

.commonQuestionsTitle {
  color: $brand-secondary-3;
  margin-bottom: 3px;
}
